const housingsModule = () => import(/* webpackChunkName: 'components/housings' */'../../components/housings/housings');
const imgGridModule = () => import(/* webpackChunkName: 'components/imgGrid' */'../../components/imgGrid/imgGrid');
const littleGenericEncartModule = () => import(/* webpackChunkName: 'components/littleGenericEncart' */'../../components/littleGenericEncart/littleGenericEncart');
const newsModule = () => import(/* webpackChunkName: 'components/news' */'../../components/news/news');
const pixleeModule = () => import(/* webpackChunkName: 'components/pixlee' */'../../components/pixlee/pixlee');
const popinActivityModule = () => import(/* webpackChunkName: 'components/popinActivity' */'../../components/popinActivity/popinActivity');
const comparePopinModule = () => import(/* webpackChunkName: 'components/comparePopin' */'../../components/comparePopin/comparePopin');

import '../../components/xp/xp';
import '../../components/cpBusiness/cpBusiness';

$(() => {
  popinActivityModule().then(({popinActivity}) => {
    popinActivity.init();
  });
  housingsModule().then(({housings}) => {
    housings.init();
    housings.getHousingData();
  });
  imgGridModule().then(({imgGrid}) => {
    imgGrid.init();
  });
  littleGenericEncartModule().then(({littleGenericEncart}) => {
    littleGenericEncart.init();
  });
  newsModule().then(({news}) => {
    news.init();
  });
  pixleeModule().then(({pixlee}) => {
    pixlee.initialize();
  });

  comparePopinModule().then(({ComparePopin}) => {
    const comparePopin = new ComparePopin();
  });

  if (!DEVICE.isDesktop && sessionStorage.getItem('basketDatas') === null) {
    $('.submenu-navBooking--button').show();
  }
});